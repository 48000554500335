import React from "react";
import logo from "../../../../assets/logo_mini.png";
import slogen from "../../../../assets/Home/1st/slogen.png";
import bg from "../../../../assets/Home/1st/bg.jpg";
//import "./style.css";
import { useStyles } from "./style";

import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

const Section1Th = () => {
  const classes = useStyles();

  return (
    <>
      <Box>
        <Box
          sx={{
            backgroundImage: {
              xl: `url(${bg})`,
              lg: `url(${bg})`,
              md: `url(${bg})`,
            },
            backgroundPosition: "center",
            marginTop: { xl: "-1%", lg: "-1%", md: "-8%" },
            width: "100%",
            height: { xl: "100vh", lg: "90vh", md: "50vh" },
            backgroundSize: "cover",
          }}
        >
          <Box
            component={"img"}
            src={logo}
            alt={"alt"}
            className={classes.hero_container_logo}
          />
          <Box>
            <Box
              component={"img"}
              src={slogen}
              alt={"alt_slogen"}
              className={classes.slogen}
            />
          </Box>

          <Box className={classes.Container_btn}>
            <NavLink to={"/eleva-coffee-selection"}>
              <Button
                variant={"outlined"}
                className={classes.hero_btn}
                sx={{
                  backgroundColor: "#edd085",
                  borderRadius: "50px",
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "goldenrod",
                  },
                }}
              >
                <Box padding={1}>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "20px",
                        lg: "18px",
                      },
                      textTransform: "capitalize",
                      color: "#000",
                      "&:hover": {
                        color: "#fff",
                      },
                    }}
                  >
                    Explore Our Coffee
                  </Typography>
                </Box>
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Section1Th;
