import React from "react";
import { Box } from "@mui/material";
import {
  Section1Th,
  Section2Nd,
  Section3RD,
  Section4Th,
  Section5Th,
  Section6Th,
  Section7Th,
  Section8Th,
  Section9Th,
} from "./components";
import SEO from "../../hooks/Seo";
import Slide1St from "./components/Section_5th/Slide1St";

const Landing = () => {
  <SEO
    title="ELEVA COFFEE | Elevated Coffee Experince"
    description="eleva coffee landing page"
    name="eleva coffee"
    type="landing-home-page"
  />;
  return (
    <>
      <Box sx={{ padding: "0%", paddingBottom: "10%" }}>
        {/* -------------  1st Section ------------- */}
        <Section1Th />
        {/* -------------  2nd Section ------------- */}
        <Section2Nd />
        {/* -------------  3rd Section ------------- */}
        <Section3RD />
        {/* -------------  4th Section ------------- */}
        <Section4Th />
        {/* -------------  5th Section ------------- */}
        <Box paddingY={0}>
          <Section5Th />
        </Box>
        {/* -------------  6th Section ------------- */}
        <Slide1St />
        <Section6Th />
        {/* -------------  7th Section ------------- */}
        <Section7Th />
        {/* -------------  8th Section ------------- */}
        <Section8Th />
        {/* -------------  9th Section ------------- */}
        <Section9Th />
        {/* -------------  10th Section ------------- */}
      </Box>
    </>
  );
};

export default Landing;
